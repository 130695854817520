export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("money", {
    created: (el, binding) => {
      console.log({ el, binding });
    },
    updated: (el, binding) => {
      console.log({ binding });
    },
  });
});
