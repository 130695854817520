<script setup>
useSeoMeta({
  title: "Seu PDV vendeu, ganhou! É Simples",
});

useTheme()
</script>

<template>
  <div>
    <NuxtPage />

    <UNotifications />
  </div>
</template>

<style>
* {
  font-family: "Lato", 'Inter';
}
</style>
