import payload_plugin_49zQDRFddS from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4pCJHUY42o from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hVF9xi2Qx9 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_L8KZJ51ydp from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_dREQojW7Wn from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0oBlLHdp8i from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dFOUyluugj from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zOqBBwwLTJ from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OCtR6OARgF from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/.nuxt/components.plugin.mjs";
import prefetch_client_MY1GS0vcMr from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._z744bgrwfst2a56f4zfrv4wvey/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_05PWxl2tiT from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.7.7_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types_e7z4exog5p3rollhjpg5bkqqo4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_9ftpXLw8PZ from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.7.7_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types_e7z4exog5p3rollhjpg5bkqqo4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_cRBhBZJsU4 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+ui@2.19.2_axios@1.7.7_change-case@5.4.4_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types_e7z4exog5p3rollhjpg5bkqqo4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_NWFjiuZzaz from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.25.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_8O0lTji9VK from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxt+icon@1.7.2_magicast@0.3.5_rollup@4.25.0_vite@5.4.11_@types+node@22.9.0_terser@5.36.0__vue@3.5.12_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_6nlFQnK5da from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.25.0_typesc_5szw6vv5huzajsmdwvjjykizbm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import Vue3Lottie_client_2RwzQTxwz0 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/Vue3Lottie.client.ts";
import maska_EdO7pVjy2z from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/maska.ts";
import vmoney_client_bVSBf9GwIM from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/vmoney.client.ts";
import vue_query_JC9gnf7CA1 from "/opt/teamcity/buildAgent/work/faa33ef3476c8a89/src/plugins/vue-query.ts";
export default [
  payload_plugin_49zQDRFddS,
  revive_payload_client_4pCJHUY42o,
  unhead_hVF9xi2Qx9,
  router_L8KZJ51ydp,
  payload_client_dREQojW7Wn,
  navigation_repaint_client_0oBlLHdp8i,
  check_outdated_build_client_dFOUyluugj,
  chunk_reload_client_zOqBBwwLTJ,
  plugin_vue3_OCtR6OARgF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MY1GS0vcMr,
  slideovers_05PWxl2tiT,
  modals_9ftpXLw8PZ,
  colors_cRBhBZJsU4,
  plugin_client_NWFjiuZzaz,
  plugin_8O0lTji9VK,
  plugin_wy0B721ODc,
  plugin_6nlFQnK5da,
  Vue3Lottie_client_2RwzQTxwz0,
  maska_EdO7pVjy2z,
  vmoney_client_bVSBf9GwIM,
  vue_query_JC9gnf7CA1
]